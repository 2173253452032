import './AlquilerCabanas.css';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { Modal } from '@mui/material';
import {SlideShow, Slide} from '../commons/SlideShow';
import CloseIcon from '@mui/icons-material/Close';
import {fotosCabanas} from '../helppers/Fotos';
import serviciosMenu from '../../assets/icos/servicios_menu.png';


const AlquilerCabanas = () => {
  window.scrollTo(0,0);
  const [modalOpen,setModalOpen] = useState(false);
  const [arrayFotos,setArrayFotos] = useState([]);

  const handleCabClick = (e) => {
    setArrayFotos(fotosCabanas[e.currentTarget.id]);
    openCloseModal();
  }
  
  const openCloseModal = () => {
      setModalOpen(!modalOpen);
  }

  const modal = (
    <div className='modal-fotos'>
        <SlideShow controles={true} autoplay={true}>
        {
            arrayFotos.map(foto => (
                <Slide key={foto} alt="villa_faraz" >
                    <img src={foto} alt='valla_faraz'/>
                </Slide>
            ))
        }
        </SlideShow>
        <div className="close-modal-fotos">
            <button onClick={openCloseModal}><CloseIcon fontSize='large' /></button>
        </div>
    </div>    
)

  return (
    <div className="alquiler">

      <div className="alquiler-header">
          <img src={fotosCabanas.cabanas_header} alt="experiencia_faraz"/>
      </div>

      <div className="alquiler-body">
        <div className="alquiler-texts">
          <div className="alquiler-texts-izq">
            <h2>Nuestras Cabañas</h2>
            <p>Con sus 8 cabañas distribuidas en una extensión de 14 hectáreas frente al mar, Villa Faraz fue pensada para que usted y su familia logren desconectarse, ya sea respirando aire puro y brisa marina, degustando deliciosos platos de autor en El Quincho, o relajándose en el área de piscina. </p>
            <p>Todo en Villa Faraz está especialmente pensado para garantizar una experiencia cálida e inolvidable. </p>
            <div className="alquiler-texts-izq-link">
              <Link to='/servicios'> Nuestros Servicios </Link>
            </div>
          </div>
          <div className="alquiler-texts-der">
            <div className="alquiler-text-der-box">
              <h3>TODAS NUESTRAS CABAÑAS CUENTAN CON:</h3>
              <ul>
                <li> Living/ Comedor, dormitorio con sommier matrimonial y baño principal.</li>
                <li>Cocina equipada con horno a gas, microondas, tostadora eléctrica, cafetera, heladera con freezer, vajilla completa.</li>
                <li>Una Smart TV Led 32” en el living/ comedor y una TV Led 32” en el dormitorio principal. </li>
                <li>Caja fuerte, ventiladores de pie, WI-FI (fibra óptica de alta velocidad), ropa blanca, galería semi cubierta con parrilla, ducha exterior, alarma monitoreada 24hs.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="cab" id="cabana1" onClick={handleCabClick}>
          <div className="cab-carrousel">
            <img src={fotosCabanas.cabana1[0]} alt="villafaraz-cab" />
          </div>
          <div className="cab-text-cell">
            <h3>Cabaña Uno</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas. </p>
            <p>Posee vestidor, y baño en suite en el dormitorio principal; bañera hidromasaje; 2 sofá camas de una plaza en el living/ comedor; antebaño.</p>
            <button>Ver Fotos</button>
          </div>
          <div className="cab-text-desktop">
            <h3>Cabaña Uno</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas. </p>
            <p>Posee vestidor, y baño en suite en el dormitorio principal; bañera hidromasaje; 2 sofá camas de una plaza en el living/ comedor; antebaño.</p>
            <button>Ver Fotos</button>
          </div>
        </div>

        <div className="cab" id="cabana2" onClick={handleCabClick}>
          <div className="cab-text-desktop cab-par">
            <h3>Cabaña Dos</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas.</p>
            <p>Posee vestidor, y baño en suite en el dormitorio principal; bañera hidromasaje; 2 sofá camas de una plaza en el living/ comedor; antebaño.</p>
            <button>Ver Fotos</button>
          </div>
          <div className="cab-carrousel">
            <img src={fotosCabanas.cabana2[0]} alt="villafaraz-cab" />
          </div>
          <div className="cab-text-cell">
            <h3>Cabaña Dos</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas.</p>
            <p>Posee vestidor, y baño en suite en el dormitorio principal; bañera hidromasaje; 2 sofá camas de una plaza en el living/ comedor; antebaño.</p>
            <button>Ver Fotos</button>
          </div>
        </div>

        <div className="cab" id="cabana4" onClick={handleCabClick}>
          <div className="cab-carrousel">
            <img src={fotosCabanas.cabana4[0]} alt="villafaraz-cab" />
          </div>
          <div className="cab-text-cell">
            <h3>Cabaña Cuatro</h3>
            <p>Está diseñada especialmente para alojar de 4 a 6 personas.</p>
            <p>Posee vestidor y baño en suite en el dormitorio principal, bañera hidromasaje, una segunda habitación con dos camas cuchetas y armario, y antebaño.</p>
            <button>Ver Fotos</button>
          </div>
          <div className="cab-text-desktop">
            <h3>Cabaña Cuatro</h3>
            <p>Está diseñada especialmente para alojar de 4 a 6 personas.</p>
            <p>Posee vestidor y baño en suite en el dormitorio principal, bañera hidromasaje, una segunda habitación con dos camas cuchetas y armario, y antebaño.</p>
            <button>Ver Fotos</button>
          </div>
        </div>

        <div className="cab" id="cabana5" onClick={handleCabClick}>
          <div className="cab-text-desktop cab-par">
            <h3>Cabaña Cinco</h3>
            <p>Está diseñada especialmente para alojar de 2 a 6 personas.</p>
            <p>Posee vestidor y baño en suite en el dormitorio principal, bañera hidromasaje, una segunda habitación con dos camas cuchetas y armario, y antebaño.</p>
            <button>Ver Fotos</button>
          </div>
          <div className="cab-carrousel">
            <img src={fotosCabanas.cabana5[0]} alt="villafaraz-cab" />
          </div>
          <div className="cab-text-cell">
            <h3>Cabaña Cinco</h3>
            <p>Está diseñada especialmente para alojar de 2 a 6 personas.</p>
            <p>Posee vestidor y baño en suite en el dormitorio principal, bañera hidromasaje, una segunda habitación con dos camas cuchetas y armario, y antebaño.</p>
            <button>Ver Fotos</button>
          </div>
        </div>

        <div className="cab" id="cabana6" onClick={handleCabClick}>
          <div className="cab-carrousel">
            <img src={fotosCabanas.cabana6[0]} alt="villafaraz-cab" />
          </div>
          <div className="cab-text-cell">
            <h3>Cabaña Seis</h3>
            <p>Está diseñada especialmente para alojar de 2 a 6 personas.</p>
            <p>Posee una segunda habitación con dos camas cuchetas y armario.</p>
            <button>Ver Fotos</button>
          </div>
          <div className="cab-text-desktop">
            <h3>Cabaña Seis</h3>
            <p>Está diseñada especialmente para alojar de 2 a 6 personas.</p>
            <p>Posee una segunda habitación con dos camas cuchetas y armario.</p>
            <button>Ver Fotos</button>
          </div>
        </div>

        <div className="cab" id="cabana7" onClick={handleCabClick}>
          <div className="cab-text-desktop cab-par">
            <h3>Cabaña Siete</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas.</p>
            <p>Posee 1 sofá cama de una plaza + 1 cama carro de una plaza en el living/ comedor.</p>
            <button>Ver Fotos</button>
          </div>
          <div className="cab-carrousel">
            <img src={fotosCabanas.cabana7[0]} alt="villafaraz-cab" />
          </div>
          <div className="cab-text-cell">
            <h3>Cabaña Siete</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas.</p>
            <p>Posee 1 sofá cama de una plaza + 1 cama carro de una plaza en el living/ comedor.</p>
            <button>Ver Fotos</button>
          </div>
        </div>

        <div className="cab" id="cabana8" onClick={handleCabClick}>
          <div className="cab-carrousel">
            <img src={fotosCabanas.cabana8[0]} alt="villafaraz-cab" />
          </div>
          <div className="cab-text-cell">
            <h3>Cabaña Ocho</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas.</p>
            <p>Posee 1 sofá cama de una plaza + 1 cama carro de una plaza en el living/ comedor.</p>
            <button>Ver Fotos</button>
          </div>
          <div className="cab-text-desktop">
            <h3>Cabaña Ocho</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas.</p>
            <p>Posee 1 sofá cama de una plaza + 1 cama carro de una plaza en el living/ comedor.</p>
            <button>Ver Fotos</button>
          </div>
        </div>

        <div className="cab" id="cabana9" onClick={handleCabClick}>
          <div className="cab-text-desktop cab-par">
            <h3>Cabaña Nueve</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas.</p>
            <p>Posee 1 sofá cama de una plaza + 1 cama carro de una plaza en el living/ comedor.</p>
            <button>Ver Fotos</button>
          </div>
          <div className="cab-carrousel">
            <img src={fotosCabanas.cabana9[0]} alt="villafaraz-cab" />
          </div>
          <div className="cab-text-cell">
            <h3>Cabaña Nueve</h3>
            <p>Está diseñada especialmente para alojar de 2 a 4 personas.</p>
            <p>Posee 1 sofá cama de una plaza + 1 cama carro de una plaza en el living/ comedor.</p>
            <button>Ver Fotos</button>
          </div>
        </div>

        <div className="nuestros-servicios-link-bottom">
          <img src={serviciosMenu} alt="villafaraz-servicios" />
          <Link to='/servicios'> Nuestros Servicios </Link>
        </div>
        
      </div>

      <Modal open={modalOpen} onClose={openCloseModal}>{modal}</Modal>

    </div>
  )
}

export default AlquilerCabanas