
import './Inicio.css';
import React from 'react';
import { Link } from 'react-router-dom';
import videoHeader from '../../assets/pagina_de_inicio/video_header.MP4';
import alquiler from '../../assets/pagina_de_inicio/alquiler.jpg';
import experiencia from '../../assets/pagina_de_inicio/experiencia.jpg';
import barrio from '../../assets/pagina_de_inicio/barrio.jpg';
import historia from '../../assets/pagina_de_inicio/historia.jpg';

const Inicio = () => {

  window.scrollTo(0,0);

  return (
    <div className="inicio">

      <div className="seccion sec1">
        <video loop autoPlay muted>
          <source src={videoHeader} type="video/mp4" />
        </video>
      </div>

      <div className="seccion sec2">
        <div className="sec2-box">
          <h2>Bienvenidos a Villa Faraz</h2>
          <p>Frente a una de las playas más extensas de la costa argentina, 
            y a solo 1, 6 km (5 minutos) del centro de Mar de Las Pampas, se encuentra Villa Faraz, 
            escondido, pero cerca de todo. Casi secreto. 
            Con una extensión de 14 hectáreas frente al mar, Villa Faraz fue pensada para que usted 
            y su familia logren desconectarse, ya sea respirando aire puro 
            y brisa marina o relajándose en el área de la piscina.
            Villa Faraz lo invita a sumergirse en el aroma de los árboles frescos, 
            el sonido del mar al despertar, los majestuosos atardeceres, y el cielo 
            cubierto de estrellas al caer la noche. Explore nuestras propuestas a continuación...</p>
        </div>
      </div>




      <Link to='/alquiler' className="inicio-links">
        <div className="seccion sec3">
          <div className="sec3-carrousel">
            <img src={alquiler} alt="villafaraz" />
          </div>
          <div className="sec3-text-cell">
            <h3>Alquiler de Cabañas</h3>
            <p>El equilibrio perfecto entre naturaleza, confort y hospitalidad.</p>
            <button>Más Información</button>
          </div>
          <div className="sec3-text-desktop">
            <h3>Alquiler de Cabañas</h3>
            <p>El equilibrio perfecto entre naturaleza, confort y hospitalidad.</p>
            <button>Más Información</button>
          </div>
        </div>
      </Link>

      <Link to='/experiencia' className="inicio-links">
        <div className="seccion sec4">
          <div className="sec4-text-desktop">
            <h3>Experiencia Faraz</h3>
            <p>Para entenderla, hay que vivirla.</p>
            <button>Más Información</button>
          </div>
          <div className="sec4-carrousel">
            <img src={experiencia} alt="villafaraz" />
          </div>
          <div className="sec4-text-cell">
            <h3>Experiencia Faraz</h3>
            <p>Para entenderla, hay que vivirla.</p>
            <button>Más Información</button>
          </div>
        </div>
      </Link>
      
      <Link to='/barrio' className="inicio-links">
        <div className="seccion sec5">
          <div className="sec5-carrousel">
            <img src={barrio} alt="villafaraz" />
          </div>
          <div className="sec5-text-cell">
            <h3>Barrio Villa Faraz</h3>
            <p>Paz. Seguridad. Naturaleza. Se puede vivir distinto.</p>
            <button>Más Información</button>
          </div>
          <div className="sec5-text-desktop">
            <h3>Barrio Villa Faraz</h3>
            <p>Paz. Seguridad. Naturaleza. Se puede vivir distinto.</p>
            <button>Más Información</button>
          </div>
        </div>
      </Link>
      





      <div className="seccion sec6">
        <div className="sec6-text-desktop">
          <h3>Nuestra Historia</h3>
          <p>Villa Faraz es el sueño de una familia y el resultado de mucho trabajo y, por sobre todo, mucho amor. Descubra la historia de un proyecto impulsado por una gran pasión por la naturaleza  y el deseo de compartir su magia. </p>
          <br/>
          <p>Conozca como nuestra familia logró crear este maravilloso espacio que hoy nos permite invitarlos a nuestro hogar y a abrazar el estilo de vida que tanto amamos.</p>
          <Link to='/historia'><button>Conocer Más</button></Link>
        </div>
        <div className="sec6-carrousel">
          <img src={historia} alt="villafaraz" />
        </div>
      </div>

    </div>
  )
}

export default Inicio;
