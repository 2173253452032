import './NuestraHistoria.css';
import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';



const NuestraHistoria = () => {

    window.scrollTo(0,0);

    return (
        <div className="historia">
            <ConstructionIcon fontSize='large' className='under-contruction-ico' />
            <p className='under-contruction-p'><i>En construcción...</i></p>
        </div>
    )
}

export default NuestraHistoria;