import './Servicios.css';
import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { SlideShow, Slide } from '../commons/SlideShow';
import CloseIcon from '@mui/icons-material/Close';
import { fotosServicios } from '../helppers/Fotos';

const AlquilerCabanas = () => {
    window.scrollTo(0, 0);
    const [modalOpen, setModalOpen] = useState(false);
    const [arrayFotos, setArrayFotos] = useState([]);

    const handleServiciosClick = (e) => {
        setArrayFotos(fotosServicios[e.currentTarget.id]);
        console.log(arrayFotos);
        openCloseModal();
    }

    const openCloseModal = () => {
        setModalOpen(!modalOpen);
    }

    const modal = (
        <div className='modal-fotos'>
            <SlideShow controles={true} autoplay={true}>
                {
                    arrayFotos.map(foto => (
                        <Slide key={foto} alt="villa_faraz" >
                            <img src={foto} alt="villa_faraz"/>
                        </Slide>
                    ))
                }
            </SlideShow>
            <div className="close-modal-fotos">
                <button onClick={openCloseModal}><CloseIcon fontSize='large' /></button>
            </div>
        </div>
    )

    return (
        <div className="servicios">

            <div className="servicios-header">
                <img src={fotosServicios.servicios_body[7]} alt="villa_faraz" />
            </div>

            <div className="servicios-body">
                
                <div className="servicios-texts">
                    <p>Con una extensión de 14 hectáreas frente al mar, Villa Faraz fue pensada para que usted y su familia logren desconectarse, ya sea respirando aire puro y brisa marina, degustando deliciosos platos de autor en El Quincho, o relajándose en el área de piscina.</p>
                    <p>Nuestro objetivo es garantizar una experiencia cálida e inolvidable</p>
                </div>

                <div className="servicios-piscina">
                    <div className="servicios-piscina-img">
                        <img src={fotosServicios.servicios_body[0]} alt="villa_faraz" />
                    </div>
                    <div className="servicios-piscina-text">
                        <h3>Piscina</h3>
                        <p> La piscina al aire libre, con confortables reposeras y sombrillas, 
                            le brindan la oportunidad de refrescarse y disfrutar del sol al reparo del viento.</p>
                    </div>
                </div>

                {/* <div className="servicios-quincho">
                    <div className="servicios-quincho-img quincho-cell">
                        <div><img src={fotosServicios.servicios_body[1]} alt="villa_faraz" /></div>
                        <div><img src={fotosServicios.servicios_body[2]} alt="villa_faraz" /></div>
                    </div>
                    <div className="servicios-quincho-img1 quincho-desktop">
                        <img src={fotosServicios.servicios_body[2]} alt="villa_faraz" />
                    </div>
                    <div className="servicios-quincho-text">
                        <h3>El Quincho</h3>
                        <p>Comience el día con una gran sonrisa en la cara y disfrute de un placentero desayuno en la comodidad de su cabaña o en nuestro salón, con vista a la piscina, disfrutando el sol de la mañana.</p>
                        <p>El Quincho prioriza su comodidad en todo momento, por lo que usted puede disfrutar de exquisitos platos a la carta en la comodidad de su cabaña, en el salón del restaurante, junto a la piscina o incluso en la playa.</p>
                        <p>Tanto por la tarde, cuando baja el sol, como por la noche; El Quincho es el punto de encuentro de nuestros huéspedes, invitándoles a disfrutar de una variedad de platos de autor en un clima de armonía y buena energía.</p>
                        <button id="quincho" onClick={handleServiciosClick}>Ver Más Fotos</button>
                    </div>
                    <div className="servicios-quincho-img2 quincho-desktop">
                        <img src={fotosServicios.servicios_body[1]} alt="villa_faraz" />
                    </div>
                </div> */}

                <div className="servicios-parador">
                    <div className="servicios-parador-img-cell parador-cell">
                        <img src={fotosServicios.servicios_body[3]} alt="villa_faraz" />
                    </div>
                    <div className="servicios-parador-text">
                        <h3>Parador</h3>
                        <p>Su estadía incluye servicio de playa en nuestro parador, situado a metros del complejo, al cual se accede a través de un sendero privado en el bosque enmarcado por frondosa vegetación.</p>
                        <p>El Parador cuenta con su propio restaurante, donde podrá disfrutar de una amplia variedad de platos.</p>
                    </div>
                    <div className="servicios-parador-img">
                        <img src={fotosServicios.servicios_body[3]} alt="villa_faraz" />
                    </div>
                </div>

                <div className="servicios-atencion">
                    <div className="servicios-atencion-cell">
                        <div className="servicios-atencion-cell-img">
                            <img src={fotosServicios.servicios_body[5]} alt="villafaraz" />
                        </div>
                        <div className="servicios-atencion-cell-text">
                            <h3>Atención Personalizada</h3>
                            <p>Nuestros huéspedes se unen a la familia Faraz en el momento que entran a nuestro 
                                resort. Durante su estadía, su bienestar será nuestra prioridad, y haremos todo lo que se encuentre a nuestro alcance para asegurarle una estancia cálida y grata. Algunos servicios a nuestros huéspedes incluyen: </p>
                            <p>- Clases de Surf a todas las edades</p>
                            <p>- Paseos en Kayak (a coordinar)</p>
                            <p>- Tablas de Sandboard y Bodyboard a disposición</p>
                            <p>- Tour del predio y visitas a los demás integrantes de nuestra familia (nuestra chancha Celeste y nuestros caballos)</p>
                            <button id="atencion" onClick={handleServiciosClick}>Ver Más Fotos</button>
                        </div>
                    </div>
                    <div className="servicios-atencion-left atencion-desktop">
                        <img src={fotosServicios.servicios_body[4]} alt="villafaraz" />
                        <h3>Atención Personalizada</h3>
                        <p>Nuestros huéspedes se unen a la familia Faraz en el momento que entran a nuestro resort. Durante su estadía, su bienestar será nuestra prioridad, y haremos todo lo que se encuentre a nuestro alcance para asegurarle una estadía cálida y grata. Algunos servicios a nuestros huéspedes incluyen: <br/>
                        - Clases de Surf a todas las edades<br/>
                        - Paseos en Kayak (a coordinar)<br/>
                        - Tablas de Sandboard y Bodyboard a disposición<br/>
                        - Tour del predio y visitas a los demás integrantes de nuestra familia (nuestra chancha Celeste y nuestros caballos)</p>
                    </div>
                    <div className="servicios-atencion-right atencion-desktop">
                        <img src={fotosServicios.servicios_body[5]} alt="villafaraz-cab" />
                        <button id="atencion" onClick={handleServiciosClick}>Ver Más Fotos</button>
                    </div>
                </div>

                <div className="servicios-hectareas">
                    <div className="servicios-hectareas-desktop">
                        <div className="servicios-hectareas-desktop-left">
                            <img src={fotosServicios.servicios_body[6]} alt="villafaraz-cab" />
                        </div>
                        <div className="servicios-hectareas-desktop-right">
                            <h3>14 Hectáreas de Bosque Frente al Mar</h3>
                            <p>Sumérjase a pleno en los encantos de la naturaleza. Con una extensión de 14 hectáreas, enmarcado con playas de gran amplitud, y rodeada de un verdadero oasis de vegetación y dunas; Villa Faraz invita al descanso, a un verdadero contacto con la naturaleza y a un silencio digno de ser escuchado. </p>
                            <img src={fotosServicios.servicios_body[7]} alt="villafaraz" />
                        </div>
                    </div>
                    <div className="servicios-hectareas-cell">
                        <div className="servicios-hectareas-cell-img">
                            <img src={fotosServicios.servicios_body[7]} alt="villafaraz" />
                        </div>
                        <div className="servicios-hectareas-cell-text">
                            <h3>14 Hectáreas de Bosque Frente al Mar</h3>
                            <p>Sumérjase a pleno en los encantos de la naturaleza. Con una extensión de 14 hectáreas, enmarcado con playas de gran amplitud, y rodeada de un verdadero oasis de vegetación y dunas; Villa Faraz invita al descanso, a un verdadero contacto con la naturaleza y a un silencio digno de ser escuchado. </p>
                        </div>
                    </div>
                </div>

            </div>
            <Modal open={modalOpen} onClose={openCloseModal}>{modal}</Modal>
        </div>
    )
}

export default AlquilerCabanas