import React from 'react';
import './NotFound.css';


const NotFound = () => {
    return (
        <div className='not-found'>
            <h2>Esta página no existe.</h2>
        </div>
    )
}

export default NotFound;