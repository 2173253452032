import './Contacto.css';
import React, {useState} from 'react';
import emailJs from 'emailjs-com';
import Modal from '@mui/material/Modal';
import {useNavigate} from 'react-router-dom';

const Contacto = () => {

    window.scrollTo(0,0);

    const [mensaje, setMensaje] = useState("");
    const [modalOpen,setModalOpen] = useState(false);
    const navigate = useNavigate();

    const sendEmail = async (e) => {
        e.preventDefault();
        await emailJs.sendForm('service_ne3xuk6', 'template_5lzxwnd', e.target, '9FDU4xc7G-5iLTXbm')
            .then(res => {
                setMensaje("Gracias por escribirnos. En breve nos comunicaremos con usted!");
            }).catch(error => {
                setMensaje("Error al enviar el mensaje. Inténtelo nuevamente.");
            });
        openModal();
    }
    const openModal = () => {
        setModalOpen(true);
    }
    const closeModal = () => {
        setModalOpen(false);
        navigate('/');
    }
    const modal = (
        <div className='modal-page'>
            <p>{mensaje}</p>  
            <button onClick={closeModal}>Volver al Home</button> 
        </div>
    )

    return (
        <div className="contacto">

            <div className="contacto-sec1">
                <h2>Ubicación</h2>
                <div className="contacto-sec1-box">
                    <div className="contacto-sec1-box-map">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3173.183856419003!2d-57.01466485017612!3d-37.31447237974698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959b608c22c3e1cf%3A0xd25afb72dc95f78f!2sVilla%20Faraz!5e0!3m2!1ses!2sar!4v1660224288349!5m2!1ses!2sar" 
                            className="google-maps"
                            allowFullScreen={true} 
                            loading="lazy" 
                            title='google-maps'
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                    <div className="contacto-sec1-box-text">
                        <p>A tan solo 1, 6 km (5 minutos) del centro de Mar de Las Pampas, Villa Faraz cuenta con una ubicación estratégica para que usted disfrute tanto de la intimidad que le ofrecen las 14 hectáreas de bosque frente al mar, como del fácil acceso al centro de Mar de las Pampas, donde puede disfrutar de sus comercios, paseos y restaurantes. Escondido, pero cerca de todo. Casi secreto.</p>
                    </div>
                </div>
            </div>

            <div className="contacto-sec2">
                <h2>Contáctenos</h2>
                <div className="contacto-sec2-box">
                    <div className="contacto-sec1-box-text">
                        <p>Nos enorgullecemos de ofrecer atención personalizada para su estadía, por lo que requerimos todas las reservas se realicen con nuestro equipo para asegurarnos de que usted obtenga exactamente lo que está buscando.</p>
                    </div>
                    <div className="contacto-sec1-box-data">
                        <p>📞 1136199683</p>
                        <p>✉ villafaraz@gmail.com</p>
                    </div>
                </div>
            </div>

            <div className="contacto-sec3">
                <p className="contacto-sec3-p">¿Quiere conocer más sobre Villa Faraz? ¡Estaremos encantados de contarle y responder a sus preguntas!</p>
                <form onSubmit={sendEmail} className="form">
                    <input className="form-input" type="text" name="name" placeholder="Nombre" required />
                    <input className="form-input" type="text" name="email" placeholder="Email" required />
                    <input className="form-input" type="text" name="phone" placeholder="Teléfono" required />
                    <textarea className="form-input text-area" name="message" placeholder="Escriba aquí su mensaje" required></textarea>
                    <p className="form-warnings"></p>
                    <input className="form-enviar" type="submit" value="Enviar" id="form-enviar" />
                </form>
            </div>

            <Modal open={modalOpen} onClose={closeModal}>{modal}</Modal>

        </div>
    )
}

export default Contacto;