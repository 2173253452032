import './BarrioVF.css';
import React from 'react';
import { Link } from 'react-router-dom';
import {SlideShow, Slide} from '../commons/SlideShow';
import img1 from '../../assets/barrio_villa_faraz/barrio_1.jpg';
import img2 from '../../assets/barrio_villa_faraz/barrio_2.jpg';
import img3 from '../../assets/barrio_villa_faraz/barrio_3.jpg';
import img4 from '../../assets/barrio_villa_faraz/barrio_4.jpg';

const BarrioVF = () => {

  window.scrollTo(0,0);

  return (
    <div className="barrio">

      <div className="barrio-carrousel">
        <SlideShow controles={true} autoplay={true}>
          <Slide>
              <img src={img1} alt="barrio_villa_faraz"/>
          </Slide>
          <Slide>
            <img src={img2} alt="barrio_villa_faraz"/>
          </Slide>
          <Slide>
            <img src={img3} alt="barrio_villa_faraz"/>
          </Slide>
          <Slide>
            <img src={img4} alt="barrio_villa_faraz"/>
          </Slide>
        </SlideShow>
      </div>

      <div className="barrio-texts">
        <h2>Bienvenidos al Barrio Villa Faraz</h2>
        <p>Descubra el ritmo de la naturaleza, priorice su calidad de vida. <br /> 23 lotes en el medio del bosque a 150 mts del mar. <br /> Lo invitamos a pertenecer a un barrio donde se vive distinto. </p>
      </div>

      <div className="barrio-contacto">
        <Link to='/contacto'>Contáctenos para Más Información</Link>
      </div>

    </div>
  )
}

export default BarrioVF