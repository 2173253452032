import './ExperienciaFaraz.css';
import React from 'react';
import {SlideShow, Slide} from '../commons/SlideShow';
import img1 from '../../assets/experiencia_faraz/experiencia_1.jpg';
import img2 from '../../assets/experiencia_faraz/experiencia_2.jpg';
import img3 from '../../assets/experiencia_faraz/experiencia_3.jpg';
import img4 from '../../assets/experiencia_faraz/experiencia_4.jpg';
import img5 from '../../assets/experiencia_faraz/experiencia_5.jpg';

const ExperienciaFaraz = () => {

    window.scrollTo(0,0);

    return (
        <div className="experiencia">
            <div className="experiencia-carrousel">
                <SlideShow controles={true} autoplay={true}>
                    <Slide>
                        <img src={img1} alt="experiencia_faraz"/>
                    </Slide>
                    <Slide>
                        <img src={img2} alt="experiencia_faraz"/>
                    </Slide>
                    <Slide>
                        <img src={img3} alt="experiencia_faraz"/>
                    </Slide>
                    <Slide>
                        <img src={img4} alt="experiencia_faraz"/>
                    </Slide>
                    <Slide>
                        <img src={img5} alt="experiencia_faraz"/>
                    </Slide>
                </SlideShow>
            </div>
            <div className="experiencia-texts">
                <h2>Experiencia Faraz</h2>
                <p>Vivimos conectados... pero ausentes; en movimiento... pero en modo avión. Es tiempo de frenar y tomar consciencia, de desconectarnos para reconectarnos. <br /> Animate a sumergirte en la Experiencia Faraz, que combina distintas prácticas buscando el bienestar tanto físico como mental en un viaje hacia tu mejor versión.</p>
                <h3>EXPERIENCIA FARAZ</h3>
                <p>Para entenderla, hay que vivirla.</p>
                <h4>Próximamente...</h4>
            </div>
        </div>
    )
}

export default ExperienciaFaraz;