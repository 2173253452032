import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import home from '../../assets/icos/home.png';

const Header = () => {
    const menuHamburgaRef = useRef();

    const openCloseMenu = () => {
        menuHamburgaRef.current.classList.toggle('hide');
    }


    return (
        <div className="header">
            <div className="header-izquierda">
                <div className="header-hamburga">
                    <button onClick={openCloseMenu} ><MenuIcon /></button>
                </div>
                <div className="header-logo">
                    <Link to="/">
                        <h1>Villa Faraz</h1>
                        <img src={home} alt="home" />
                    </Link>
                </div>
                <div className="header-compensa">
                </div>
            </div>
            <div ref={menuHamburgaRef} className="header-menu hide">
                <Link to="/alquiler" onClick={openCloseMenu}>ALQUILER CABAÑAS</Link>
                <Link to="/barrio" onClick={openCloseMenu}>BARRIO VF</Link>
                <Link to="/experiencia" onClick={openCloseMenu}>EXPERIENCIA FARAZ</Link>
                <Link to="/historia" onClick={openCloseMenu}>NUESTRA HISTORIA</Link>
                <Link to="/contacto" onClick={openCloseMenu}>CONTACTO</Link>
            </div>
            <div className="header-menu-desktop">
                <Link to="/alquiler">ALQUILER CABAÑAS</Link>
                <Link to="/barrio">BARRIO VF</Link>
                <Link to="/experiencia">EXPERIENCIA FARAZ</Link>
                <Link to="/historia">NUESTRA HISTORIA</Link>
                <Link to="/contacto">CONTACTO</Link>
            </div>
        </div>
    )
}

export default Header