//import cab headder: 
import cab0 from '../../assets/alquiler_de_cabanas/cab_header/cab0.jpg';
//import cab 1:
import cab1_1 from '../../assets/alquiler_de_cabanas/cab_1/cab1_1.jpg';
import cab1_2 from '../../assets/alquiler_de_cabanas/cab_1/cab1_2.jpg';
import cab1_3 from '../../assets/alquiler_de_cabanas/cab_1/cab1_3.jpg';
import cab1_4 from '../../assets/alquiler_de_cabanas/cab_1/cab1_4.jpg';
import cab1_5 from '../../assets/alquiler_de_cabanas/cab_1/cab1_5.jpg';
import cab1_6 from '../../assets/alquiler_de_cabanas/cab_1/cab1_6.jpg';
import cab1_7 from '../../assets/alquiler_de_cabanas/cab_1/cab1_7.jpg';
import cab1_8 from '../../assets/alquiler_de_cabanas/cab_1/cab1_8.jpg';
import cab1_9 from '../../assets/alquiler_de_cabanas/cab_1/cab1_9.jpg';
import cab1_10 from '../../assets/alquiler_de_cabanas/cab_1/cab1_10.jpg';
//import cab 2:
import cab2_1 from '../../assets/alquiler_de_cabanas/cab_2/cab2_1.jpg';
import cab2_2 from '../../assets/alquiler_de_cabanas/cab_2/cab2_2.jpg';
import cab2_3 from '../../assets/alquiler_de_cabanas/cab_2/cab2_3.jpg';
import cab2_4 from '../../assets/alquiler_de_cabanas/cab_2/cab2_4.jpg';
import cab2_5 from '../../assets/alquiler_de_cabanas/cab_2/cab2_5.jpg';
import cab2_6 from '../../assets/alquiler_de_cabanas/cab_2/cab2_6.jpg';
import cab2_7 from '../../assets/alquiler_de_cabanas/cab_2/cab2_7.jpg';
import cab2_8 from '../../assets/alquiler_de_cabanas/cab_2/cab2_8.jpg';
import cab2_9 from '../../assets/alquiler_de_cabanas/cab_2/cab2_9.jpg';
import cab2_10 from '../../assets/alquiler_de_cabanas/cab_2/cab2_10.jpg';
//import cab 4:
import cab4_1 from '../../assets/alquiler_de_cabanas/cab_4/cab4_1.jpg';
import cab4_2 from '../../assets/alquiler_de_cabanas/cab_4/cab4_2.jpg';
import cab4_3 from '../../assets/alquiler_de_cabanas/cab_4/cab4_3.jpg';
import cab4_4 from '../../assets/alquiler_de_cabanas/cab_4/cab4_4.jpg';
import cab4_5 from '../../assets/alquiler_de_cabanas/cab_4/cab4_5.jpg';
import cab4_6 from '../../assets/alquiler_de_cabanas/cab_4/cab4_6.jpg';
import cab4_7 from '../../assets/alquiler_de_cabanas/cab_4/cab4_7.jpg';
import cab4_8 from '../../assets/alquiler_de_cabanas/cab_4/cab4_8.jpg';
import cab4_9 from '../../assets/alquiler_de_cabanas/cab_4/cab4_9.jpg';
import cab4_10 from '../../assets/alquiler_de_cabanas/cab_4/cab4_10.jpg';
//import cab 5:
import cab5_1 from '../../assets/alquiler_de_cabanas/cab_5/cab5_1.jpg';
import cab5_2 from '../../assets/alquiler_de_cabanas/cab_5/cab5_2.jpg';
import cab5_3 from '../../assets/alquiler_de_cabanas/cab_5/cab5_3.jpg';
import cab5_4 from '../../assets/alquiler_de_cabanas/cab_5/cab5_4.jpg';
import cab5_5 from '../../assets/alquiler_de_cabanas/cab_5/cab5_5.jpg';
import cab5_6 from '../../assets/alquiler_de_cabanas/cab_5/cab5_6.jpg';
import cab5_7 from '../../assets/alquiler_de_cabanas/cab_5/cab5_7.jpg';
import cab5_8 from '../../assets/alquiler_de_cabanas/cab_5/cab5_8.jpg';
import cab5_9 from '../../assets/alquiler_de_cabanas/cab_5/cab5_9.jpg';
//import cab 6:
import cab6_1 from '../../assets/alquiler_de_cabanas/cab_6/cab6_1.jpg';
import cab6_2 from '../../assets/alquiler_de_cabanas/cab_6/cab6_2.jpg';
import cab6_3 from '../../assets/alquiler_de_cabanas/cab_6/cab6_3.jpg';
import cab6_4 from '../../assets/alquiler_de_cabanas/cab_6/cab6_4.jpg';
import cab6_5 from '../../assets/alquiler_de_cabanas/cab_6/cab6_5.jpg';
import cab6_6 from '../../assets/alquiler_de_cabanas/cab_6/cab6_6.jpg';
import cab6_7 from '../../assets/alquiler_de_cabanas/cab_6/cab6_7.jpg';
import cab6_8 from '../../assets/alquiler_de_cabanas/cab_6/cab6_8.jpg';
import cab6_9 from '../../assets/alquiler_de_cabanas/cab_6/cab6_9.jpg';
import cab6_10 from '../../assets/alquiler_de_cabanas/cab_6/cab6_10.jpg';
//import cab 7:
import cab7_1 from '../../assets/alquiler_de_cabanas/cab_7/cab7_1.jpg';
import cab7_2 from '../../assets/alquiler_de_cabanas/cab_7/cab7_2.jpg';
import cab7_3 from '../../assets/alquiler_de_cabanas/cab_7/cab7_3.jpg';
import cab7_4 from '../../assets/alquiler_de_cabanas/cab_7/cab7_4.jpg';
import cab7_5 from '../../assets/alquiler_de_cabanas/cab_7/cab7_5.jpg';
import cab7_6 from '../../assets/alquiler_de_cabanas/cab_7/cab7_6.jpg';
import cab7_7 from '../../assets/alquiler_de_cabanas/cab_7/cab7_7.jpg';
import cab7_8 from '../../assets/alquiler_de_cabanas/cab_7/cab7_8.jpg';
import cab7_9 from '../../assets/alquiler_de_cabanas/cab_7/cab7_9.jpg';
import cab7_10 from '../../assets/alquiler_de_cabanas/cab_7/cab7_10.jpg';
//import cab 8:
import cab8_1 from '../../assets/alquiler_de_cabanas/cab_8/cab8_1.jpg';
import cab8_2 from '../../assets/alquiler_de_cabanas/cab_8/cab8_2.jpg';
import cab8_3 from '../../assets/alquiler_de_cabanas/cab_8/cab8_3.jpg';
import cab8_4 from '../../assets/alquiler_de_cabanas/cab_8/cab8_4.jpg';
import cab8_5 from '../../assets/alquiler_de_cabanas/cab_8/cab8_5.jpg';
import cab8_6 from '../../assets/alquiler_de_cabanas/cab_8/cab8_6.jpg';
import cab8_7 from '../../assets/alquiler_de_cabanas/cab_8/cab8_7.jpg';
import cab8_8 from '../../assets/alquiler_de_cabanas/cab_8/cab8_8.jpg';
import cab8_9 from '../../assets/alquiler_de_cabanas/cab_8/cab8_9.jpg';
import cab8_10 from '../../assets/alquiler_de_cabanas/cab_8/cab8_10.jpg';
//import cab 9:
import cab9_1 from '../../assets/alquiler_de_cabanas/cab_9/cab9_1.jpg';
import cab9_2 from '../../assets/alquiler_de_cabanas/cab_9/cab9_2.jpg';
import cab9_3 from '../../assets/alquiler_de_cabanas/cab_9/cab9_3.jpg';
import cab9_4 from '../../assets/alquiler_de_cabanas/cab_9/cab9_4.jpg';
import cab9_5 from '../../assets/alquiler_de_cabanas/cab_9/cab9_5.jpg';
import cab9_6 from '../../assets/alquiler_de_cabanas/cab_9/cab9_6.jpg';
import cab9_7 from '../../assets/alquiler_de_cabanas/cab_9/cab9_7.jpg';
import cab9_8 from '../../assets/alquiler_de_cabanas/cab_9/cab9_8.jpg';
import cab9_9 from '../../assets/alquiler_de_cabanas/cab_9/cab9_9.jpg';
import cab9_10 from '../../assets/alquiler_de_cabanas/cab_9/cab9_10.jpg';
//import servicios body:
import servicios_1 from '../../assets/servicios/servicios_body/servicios_1.jpg';
import servicios_2 from '../../assets/servicios/servicios_body/servicios_2.jpg';
import servicios_3 from '../../assets/servicios/servicios_body/servicios_3.jpg';
import servicios_4 from '../../assets/servicios/servicios_body/servicios_4.jpg';
import servicios_5 from '../../assets/servicios/servicios_body/servicios_5.jpg';
import servicios_6 from '../../assets/servicios/servicios_body/servicios_6.jpg';
import servicios_7 from '../../assets/servicios/servicios_body/servicios_7.jpg';
import servicios_8 from '../../assets/servicios/servicios_body/servicios_8.jpg';
//import servicios atencion:
import atencion_1 from '../../assets/servicios/atencion/atencion_1.jpg';
import atencion_2 from '../../assets/servicios/atencion/atencion_2.jpg';
import atencion_3 from '../../assets/servicios/atencion/atencion_3.jpg';
import atencion_4 from '../../assets/servicios/atencion/atencion_4.jpg';
import atencion_5 from '../../assets/servicios/atencion/atencion_5.jpg';
import atencion_6 from '../../assets/servicios/atencion/atencion_6.jpg';
//import servicios quincho:
import quincho_1 from '../../assets/servicios/quincho/quincho_1.jpg';
import quincho_2 from '../../assets/servicios/quincho/quincho_2.jpg';
import quincho_3 from '../../assets/servicios/quincho/quincho_3.jpg';
import quincho_4 from '../../assets/servicios/quincho/quincho_4.jpg';  
import quincho_5 from '../../assets/servicios/quincho/quincho_5.jpg';
import quincho_6 from '../../assets/servicios/quincho/quincho_6.jpg';
import quincho_7 from '../../assets/servicios/quincho/quincho_7.jpg';
import quincho_8 from '../../assets/servicios/quincho/quincho_8.jpg';
import quincho_9 from '../../assets/servicios/quincho/quincho_9.jpg';


export const fotosCabanas = {
    "cabanas_header": cab0,
    "cabana1" : [cab1_1,cab1_2,cab1_3,cab1_4,cab1_5,cab1_6,cab1_7,cab1_8,cab1_9,cab1_10],
    "cabana2" : [cab2_1,cab2_2,cab2_3,cab2_4,cab2_5,cab2_6,cab2_7,cab2_8,cab2_9,cab2_10],
    "cabana4" : [cab4_1,cab4_2,cab4_3,cab4_4,cab4_5,cab4_6,cab4_7,cab4_8,cab4_9,cab4_10],
    "cabana5" : [cab5_1,cab5_2,cab5_3,cab5_4,cab5_5,cab5_6,cab5_7,cab5_8,cab5_9],
    "cabana6" : [cab6_1,cab6_2,cab6_3,cab6_4,cab6_5,cab6_6,cab6_7,cab6_8,cab6_9,cab6_10],
    "cabana7" : [cab7_1,cab7_2,cab7_3,cab7_4,cab7_5,cab7_6,cab7_7,cab7_8,cab7_9,cab7_10],
    "cabana8" : [cab8_1,cab8_2,cab8_3,cab8_4,cab8_5,cab8_6,cab8_7,cab8_8,cab8_9,cab8_10],
    "cabana9" : [cab9_1,cab9_2,cab9_3,cab9_4,cab9_5,cab9_6,cab9_7,cab9_8,cab9_9,cab9_10],
}
export const fotosServicios = {
    "servicios_body": [servicios_1,servicios_2,servicios_3,servicios_4,servicios_5,servicios_6,servicios_7,servicios_8],
    "atencion" : [atencion_1,atencion_2,atencion_3,atencion_4,atencion_5,atencion_6],
    "quincho" : [quincho_1,quincho_2,quincho_3,quincho_4,quincho_5,quincho_6,quincho_7,quincho_8,quincho_9]
}
