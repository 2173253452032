import './WhatsIco.css';
import React from 'react';
// import whatsApp from '../../assets/icos/whatsapp.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsIco = () => {
    return (
        <a href='https://api.whatsapp.com/send?phone=5491136199683' className='whatshapp-floating-a' target='_blank' rel="noreferrer">
            <WhatsAppIcon className='whatshapp-floating-ico' fontSize='50px' />
        </a>
    )
}

export default WhatsIco;
