import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {createBrowserHistory} from 'history';
//HOME:
import Home from "../components/home/Home";
//OUTLETS:
import Inicio from "../components/outlets/Inicio";
import NuestraHistoria from "../components/outlets/NuestraHistoria";
import Contacto from "../components/outlets/Contacto";
import ExperienciaFaraz from "../components/outlets/ExperienciaFaraz";
import BarrioVF from "../components/outlets/BarrioVF";
import AlquilerCabanas from "../components/outlets/AlquilerCabanas";
import Servicios from "../components/outlets/Servicios";
//OTHERS:
import NotFound from "../components/commons/NotFound";



function AppRoutes() {
    return (
        <Router onUpdate={() => window.scrollTo(0, 0)} history={createBrowserHistory()} >
            <Routes>
                <Route path="/" element={<Home />}>
                    <Route path="" element={<Inicio />} />
                    <Route path="alquiler" element={<AlquilerCabanas />} />
                    <Route path="barrio" element={<BarrioVF />} />
                    <Route path="experiencia" element={<ExperienciaFaraz />} />
                    <Route path="historia" element={<NuestraHistoria />} />
                    <Route path="contacto" element={<Contacto />} />
                    <Route path="servicios" element={<Servicios />} />
				    <Route path='*' element={<NotFound />} />
                </Route>
            </Routes>
        </Router>
    )
}

export default AppRoutes;

