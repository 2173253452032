import React from 'react'
import { Link } from 'react-router-dom';
import InstagramIcon from '../../assets/icos/instagram.png';
import FacebookIcon from '../../assets/icos/facebook.png';
import EmailIcon from '../../assets/icos/email.png';
const Footer = () => {
    return (
        <div className="footer">
            <Link to="/contacto" className="footer-ubicacion">
                <h3>UBICACIÓN</h3>
                <p>Paseo 169 y Playa,</p>
                <p>B7165 Villa Gesell,</p>
                <p>Provincia de BsAs.</p>
            </Link>
            <Link to="/contacto" className="footer-contacto" replace>
                <h3>CONTACTO</h3>
                <p>Reservas</p>
                <p>Consultas</p>
            </Link>
            <div className="footer-redes">
                <a href="https://www.instagram.com/villafaraz/" target="_blanck"><img src={InstagramIcon} alt='instagram'/></a>
                <a href="https://www.facebook.com/villafaraz/" target="_blanck"><img src={FacebookIcon} alt='facebook'/></a>
                <a href="mailto:villafaraz@gmail.com" target="_blanck"><img src={EmailIcon} alt='mail'/></a>
            </div>
            <div className="footer-creditos">
                <p>© Sitio desarrollado por Sebastián Coppola para Villa Faraz 2022</p>
            </div>
        </div>
    )
}

export default Footer;